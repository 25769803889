@import './color.scss';

html,
body {
  height: 100%;
  width: 100%;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

#root {
  height: 100%;
}

[data-theme='light'] {
  #root {
    background: $secondary;
  }
}

[data-theme='dark'] {
  #root {
    background: $primary;
  }
}
