@import '../../../styles/color.scss';

.notice {
  position: absolute;
  top: 72px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  z-index: 2000;
}

.noticeContent {
  max-height: 150px;
  padding: 16px 20px;
  border-radius: 15px;
}

.error {
  background-color: $notice-background-error;
}

.success {
  background-color: $notice-background-success;
}

.warning {
  background-color: $notice-background-warning;
}
