$primary: #1d1d1d;
$secondary: #ffffff;
$lime: #7fff00;
$error-text: #ff0a56;

$background-White-100: #ffffff;

$background-Grey-100: #1d1d1d;
$background-Grey-10: #1d1d1d1a;
$background-Grey-05: #f0f0f0;
$background-Grey-30: #1d1d1d4d;

$background-light-grey: #f0f0f0;

// Notice ==========

$notice-background-success: #7fff00;
$notice-background-warning: #ffe923;
$notice-background-error: #db2c2c;

// PinInput ==========

$pin-input-background: #ffffff;
$pin-input-border: #1d1d1d2a;
$pin-input-border-bottom-error: #ff0a56;
